<template>
  <div class="fixed flex bg-white z-10 justify-between py-3 px-10 inset-x-0 border-b border-gray300">
    <!-- TODO: merge with main top bar -->

    <div class="flex items-center gap-3">
      <slot name="title">
        <div class="h-10">
          <Icon
            name="logo-new"
            class="h-40 absolute -top-12"
          />
        </div>
      </slot>
    </div>

    <slot name="actions" />
  </div>
  <Divider class="!m-0" />
</template>
